<template>
	<div class="keyword-special-detail full-width">

		<search class="keyword-special-detail-header" v-model="form" placeholder="项目ID、项目名称搜索" @click="getList(true)" :advanced="false" >
          <template slot="base">
			<el-form-item label="词汇" label-width="80px">
				<el-input v-model="form.word"></el-input>
			</el-form-item>
			<el-form-item label="翻译语言" label-width="80px">
				<el-select v-model="form.languageId" placeholder="翻译语言" clearable>
					<el-option
					v-for="(item,index) in $store.state.language"
					:key="index"
					:label="item.name"
					:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="翻译状态" label-width="80px">
				<el-select v-model="form.status" placeholder="翻译状态" clearable>
					<el-option :value="0" label="未翻译">未翻译</el-option>
					<el-option :value="1" label="已翻译">已翻译</el-option>
				</el-select>
			</el-form-item>
		  </template>
		</search>

		<div class="keyword-special-detail-content table-margin">
			<el-card>
				<el-row>
					<el-col :span="24">
						<el-table size="mini" border :data="tableData">
							<el-table-column prop="id" label="ID" min-width="2%" align="center"></el-table-column>
							<el-table-column prop="word" label="词汇" min-width="8%" align="center"></el-table-column>
							<el-table-column :formatter="global.utils.format.language" prop="languageId" label="原始语种" min-width="8%" align="center"></el-table-column>
							<el-table-column :formatter="global.utils.format.wordType" prop="contentType" label="类型" min-width="8%" align="center"></el-table-column>
							<el-table-column label="操作" min-width="8%" align="center">
								<template slot-scope="scope">
									<el-button type="text" @click="doTransfer(scope.row)">翻译</el-button>
									<el-button type="text" @click="viewTransfer(scope.row)">查看</el-button>
									<el-button type="text" @click="remove(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<div class="full-width" style="text-align:center;margin-top:20px;">
					<el-pagination				
					    layout="prev, pager, next , total"
					    :total="total"
					    :page-size.sync="page_size"
					    :current-page.sync = 'page'
					    >
					</el-pagination>
				</div>
			</el-card>
		</div>

		<el-dialog title="词汇翻译详情" :visible.sync="viewTransferShow">
			<v-simple-table>
				<thead>
					<tr>
						<th class="text-center">语种</th>
						<th class="text-center">翻译内容</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item , index) in viewTransferData" :key="index">
						<td class="text-center">{{item.name}}</td>
						<td class="text-center">{{item.word}}</td>
					</tr>
				</tbody>
			</v-simple-table>
		</el-dialog>

		<el-dialog title="翻译词条" :visible.sync="transferShow">
			<el-form label-width="80px">
				<el-form-item
				v-for="(item , index) in transferData.transferList" 
				:key="index"
				:label="item.name"
				>
					<el-input class="middle-item" :disabled="!item.editable" v-model="item.word"></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer">
				<el-button size="small" @click="transferShow = false">取消</el-button>
				<el-button type="primary" size="small" @click="transfer">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : 'keyword-special-detail',
		data (){
			return {
				form : {},
				total : 0,
				page : 1,
				page_size : 10,
				tableData : [],
				viewTransferShow : false,
				viewTransferData : [],
				transferShow : false,
				transferData : []
			}
		},
		methods : {
			getList(reload = false){
				if(reload)this.page = 1;
				$$.get("/api-intl-translate/proper-word/query" , {
					params : _.extend(this.form , {
						properDatabaseId : this.$route.query.id,
						page : this.page , 
						size : this.page_size,
						isDeleted : 0
					})
				}).then(data => {
					this.tableData = data.content.map(val => {
						val.transferList = this.$store.state.language.map(language => {
							let transfer = val.properWordTranslateList.filter(v => {
								return v.languageId == language.id;
							})
							return {
								languageId : language.id,
								name : language.name,
								word : transfer.length > 0 ? transfer[0].word : ""
							}
						})
						return val;
					});
					this.total = data.totalElements;
				})
			},
			remove(row){
				this.$confirm("确定删除该词汇？" , "提示" , {
					confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
				}).then(data => {
					$$.delete("/api-intl-translate/proper-word?id=" + row.id).then(data => {
						this.$message.success("删除成功");
						this.getList();
					}).catch(err => {
						this.$message.error(err.message);
					})
				}).catch(action => {})
			},
			viewTransfer(row){
				this.viewTransferData = row.transferList;
				this.viewTransferShow  = true;
			},
			doTransfer(row){
				$$.get("/api-intl-translate/proper-word/detail" , {
					params : {
						id : row.id
					}
				}).then(data => {
					console.log(data);
					let single = _.cloneDeep(row);
					single.transferList = data.properWordTranslateList.map(val => {
						val.name = utils.format.language(null , null , val.languageId);
						return val;
					})
					this.transferData = single;
					this.transferShow  = true;
				})
				
			},
			transfer(){
				let data = _.clone(this.transferData);
				data.properWordTranslateList = data.transferList.map(val => {
					return {
						languageId : val.languageId,
						word : val.word
					}
				})
				delete data.transferList;
				$$.post("/api-intl-translate/proper-word" , data).then(data => {
					this.$message.success("保存成功");
					this.transferShow = false;
					this.getList();
				}).catch(err => {
					this.$message.error(err.message);
				})
			}
		},
		created (){
			this.getList();
		},
		watch : {
			page (){
				this.getList();
			},
			page_size(){
				this.getList(true)
			}
		}
	};
</script>