<template>
  <div class="third-pard-subtile-compare full-width">
    <!-- 进入页面选择页面和语种 -->
    <el-dialog :visible.sync="configDialog" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="resetUrl" title="页面配置">
      <el-form :rules="rules" :model="configData" class="form" label-width="80px" ref="config" @submit.native.prevent>
        <el-form-item label="页面名称" prop="name">
          <el-input class="input" v-model="configData.name" />
        </el-form-item>

        <el-form-item label="原始语种">
          中文
        </el-form-item>

        <el-form-item label="翻译语种" prop="translateLanguageList">
          <el-checkbox-group v-model="configData.translateLanguageList">
            <el-checkbox class="d-inline-flex justify-left align-center" style="width : 60px;height : 40px;" v-for="item in language.filter(data => data.id != 1)" :key="item.id" :label="item.id">
              {{item.name}}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button type="primary" @click="confirm()">确定</el-button>
      </div>
    </el-dialog>

    <div class="tip-content" v-if="showTip">
      <div class="tip">
        <div class="close-tip-button-content">
          <i class="el-icon-close close-tip-button" @click="showTip = false" />
        </div>
        <div class="tip-title">字幕匹配规则（导入字幕）</div>
        <li class="tip-text">分片原文与对应分片译文的时间轴必须一致</li>
        <li class="tip-text">分片原文与成片的字幕内容必须一致</li>
        <div class="tip-title" style="margin-top: 20px;">补译版修改规则</div>
        <li class="tip-text">句前标记“#”，表示当前句未成功匹配，需要进行检查补译。示例：# 我已经到水族馆了</li>
        <li class="tip-text">句末标记“##”，表示当前句有多种译文，需要进行检查，多种译文见括号。当“你好”有2种译文时，① hi ② hello，示例：hello ## (展示上下文匹配度较高的译文)</li>
      </div>
    </div>
    <div class="uploader-content">
      <div>
        <div class="shard-content">
          <div class="shard" v-for="(item , index) in shards" :key="index">
            <div class="shard-add">
              <el-button v-if="index + 1 == shards.length && shards.length < 30" type="primary" size="mini" @click="addShard">添加分片</el-button>
            </div>
            <span class="shard-label">分片{{index + 1}}：</span>

            <div class="shard-input">
              <div class="shard-input-single">
                <label class="shard-input-label">原文</label>
                <input-file v-model="item.originFile"></input-file>
              </div>
              <div v-for="(translateLangId, index) of configData.translateLanguageList" :key="translateLangId" class="shard-input-single">
                <label class="shard-input-label">译文({{getLangName(translateLangId)}})</label>
                <input-file v-model="item.translateFileList[index]"></input-file>
                <div>
                  <el-button type="primary" size="mini" @click="checkSharkCompare(item, index)">检测匹配情况</el-button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="full-width">
          <el-divider></el-divider>
        </div>

        <div class="complete-content">
          <div class="shard">
            <div class="shard-add">

            </div>

            <span class="shard-label">成片</span>

            <div class="shard-input">
              <div class="shard-input-single">
                <label class="shard-input-label">原文</label>
                <input-file v-model="completeFile"></input-file>
              </div>
            </div>
            <div>
              <el-button type="primary" size="mini" @click="checkCompleteCompare">检测匹配情况</el-button>

            </div>

            <div>

            </div>
          </div>
          <div class="shard" v-for="(translateLangId, index) of configData.translateLanguageList" :key="translateLangId">
            <div class="shard-add">

            </div>

            <span class="shard-label"></span>

            <div class="shard-input">
              <div class="shard-input-single">
                <label class="shard-input-label">译文({{getLangName(translateLangId)}})</label>
              </div>
            </div>

            <div>
              <el-button type="primary" size="mini" @click="download(0, index)">输出线上版</el-button>
              <el-button type="primary" size="mini" @click="download(1, index)">输出补译版</el-button>
              <el-button type="primary" size="mini" @click="download(2, index)">输出双语版</el-button>
            </div>
          </div>

        </div>

        <div class="complete-content">
          <div class="shard">
            <div class="shard-add">

            </div>
            <div class="shard-label">

            </div>

            <div style="width: 100%;">
              <el-button type="primary" size="mini" style="width: 100%;" @click="batchDownload">批量下载</el-button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script type="text/javascript">
function sleep(time) {
  return new Promise((resove) => setTimeout(() => resove(time), time));
}
import axios from "axios";
let subtitle$$ = axios.create({});
export default {
  name: "third-pard-subtile-compare",
  data() {
    return {
      configDialog: true,
      showTip: true,
      configData: {
        originalLanguage: 1,
        translateLanguageList: [],
      },
      shards: [
        {
          originFile: "",
          translateFileList: [],
        },
      ],
      completeFile: "",
      language: [],
      rules: {
        name: [
          {
            required: true,
            whitespace: true,
            message: "请填写页面名称",
          },
          {
            max: 40,
            message: "最多填写40个字符",
          },
        ],
        translateLanguageList: [
          {
            type: "array",
            required: true,
            message: "请选择翻译语种",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return `${this.configData.name}`;
    },
  },
  methods: {
    async batchDownload() {
      for (
        let index = 0;
        index < this.configData.translateLanguageList.length;
        index++
      ) {
        this.download(0, index);
        await sleep(50);
        this.download(1, index);
        await sleep(50);
        this.download(2, index);
        await sleep(50);
      }
    },
    getLanguage() {
      $$.get("/api-intl-translate/operation-anonymous/language").then(
        (data) => {
          this.language = data;
        }
      );
    },
    getLangName(langId) {
      for (let lang of this.language) {
        if (lang.id === langId) {
          return lang.name;
        }
      }
      return "译文";
    },
    confirm() {
      this.$refs.config.validate((valid) => {
        if (valid) {
          document.title = `${this.configData.name}`;
          this.configDialog = false;
          this.shards[0].translateFileList = new Array(
            this.configData.translateLanguageList.length
          );
        }
      });
    },
    checkSharkCompare(row, index) {
      let translateLang = this.configData.translateLanguageList[index];
      let url = `/api-intl-translate-vod/subtitle-matcher/slice-match-percent`;
      let params = {
        originLang: this.configData.originalLanguage,
        translateLang,
        originFile: row.originFile,
        translateFile: row.translateFileList[index],
      };

      $$.post(url, this.getFormData(params))
        .then((data) => {
          let content = `原始字幕匹配程度${data.originPercent}，翻译字幕匹配程度${data.translatePercent}，是否确认（备注：原始字幕和翻译字幕中时间点完全一致的字幕认为是匹配的）`;
          this.$confirm(content, "提示", {
            distinguishCancelAndClose: true,
            cancelButtonText: "取消并下载匹配报告",
          })
            .then(() => {})
            .catch((action) => {
              if (action == "cancel") {
                let downloadUrl = `/api-intl-translate-vod/subtitle-matcher/slice-match-report`;
                utils.funDownload(
                  utils.addParams(downloadUrl, {
                    originFileId: data.originFileId,
                    translateFileId: data.translateFileId,
                    originLang: this.configData.originalLanguage,
                    translateLang: this.configData.translateLanguage,
                  })
                );
              }
            });
        })
        .catch((err) => {
          if (err.message) {
            this.$message.error(err.message);
          } else {
            let downloadUrl = `data:text/plain,${encodeURIComponent(err)}`;
            utils.contentDownload(downloadUrl, "test.xls");
          }
        });
    },
    checkCompleteCompare() {
      let url = `/api-intl-translate-vod/subtitle-matcher/checked-match-report`;
      let params = {
        originLang: this.configData.originalLanguage,
        checkedOriginFile: this.completeFile,
        originFiles: this.shards.map((v) => v.originFile),
      };

      $$.post(url, this.getFormData(params))
        .then((data) => {
          //console.log(data);
        })
        .catch((err) => {
          if (err.message) {
            this.$message.error(err.message);
          } else {
            let downloadUrl = `data:text/plain,${encodeURIComponent(err)}`;
            utils.contentDownload(downloadUrl, `${this.title}成片比较结果.txt`);
          }
        });
    },
    download(type = 0, index) {
      let urls = [
        `/api-intl-translate-vod/subtitle-matcher/online-version`,
        `/api-intl-translate-vod/subtitle-matcher/translate-version`,
        `/api-intl-translate-vod/subtitle-matcher/bilingual-version`,
      ];
      let labels = ["线上版", "补译版", "双语版"];
      let url = urls[type];
      if (!url) return false;
      let params = {
        originFiles: this.shards.map((v) => v.originFile),
        translateFiles: this.shards.map((v) => v.translateFileList[index]),
        checkedOriginFile: this.completeFile,
        originLang: this.configData.originalLanguage,
        translateLang: this.configData.translateLanguageList[index],
        fileNamePre: this.title,
      };
      let langName = this.getLangName(
        this.configData.translateLanguageList[index]
      );

      subtitle$$
        .post(url, this.getFormData(params))
        .then((data) => {
          let header = data.headers;
          let filename = header["content-disposition"]
            .split(";")[1]
            .replace("filename=", "");
          let downloadUrl = `data:text/plain,${encodeURIComponent(data.data)}`;
          utils.contentDownload(
            downloadUrl,
            langName + "-" + decodeURIComponent(filename)
          );
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    getFormData(data) {
      const { FormData } = require("sync-request");
      var formData = new FormData();
      for (var k in data) {
        if (data[k] instanceof Array) {
          data[k].forEach((v) => {
            formData.append(`${k}[]`, v);
          });
        } else {
          console.log(data[k] instanceof File);
          formData.append(k, data[k]);
        }
      }

      return formData;
    },
    addShard() {
      this.shards.push({
        originFile: "",
        translateFileList: new Array(
          this.configData.translateLanguageList.length
        ),
      });
    },
    //跳转去首页
    resetUrl() {
      this.$router.push({ name: "welcome" });
    },
  },
  watch: {
    "configData.translateLanguage"(val) {
      let language = this.language.filter((v) => v.id == val);
      this.configData.translateLanguageName =
        language.length > 0 ? language[0].name : "";
    },
  },
  mounted() {
    this.getLanguage();
  },
  beforeDestroy() {
    //销毁之前恢复页面title
    document.title = "ECHO";
  },
};
</script>

<style lang="scss" scoped>
.tip-content {
  display: flex;
  justify-content: center;
}
.tip {
  width: 1150px;
  top: 50px;
  right: 150px;
  background-color: #dcdfe6;
  border-radius: 5px;
  padding: 15px;
}
.tip-title {
  margin-bottom: 20px;
}
.tip-text {
  margin-left: 5px;
}
.third-pard-subtile-compare {
  .form {
    .input {
      width: 250px;
    }
  }

  .uploader-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  .shard {
    .shard-add {
      width: 100px;
    }
    .shard-label {
      width: 65px;
    }

    display: flex;
    .shard-input {
      display: flex;
      flex-direction: column;
      .shard-input-single {
        display: flex;

        .shard-input-label {
          width: 120px;
        }
      }
      .shard-input-single + .shard-input-single {
        margin-top: 15px;
      }
    }
  }

  .shard + .shard {
    margin-top: 15px;
  }

  .complete-content {
    margin-top: 25px;
  }
  .close-tip-button-content {
    position: relative;
  }
  .close-tip-button {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 0px;
  }
  .close-tip-button:hover {
    color: #409EFF;
  }
}
</style>