<template>
	<div class="keyword-special full-width">
		<div style="position : absolute;top : 0;bottom : 0;left : 0;right : 0;overflow : hidden;background-color: #d9d9d9;" class="d-flex justify-center align-center">
			<img src="../../assets/img/constructing.jpg" width="516px">
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : 'keyword-special'
	};
</script>