<template>
	<div class="keyword-word-filter full-width">
		<search class="cooperation-employee-header" v-model="form" placeholder="规则id，过滤词名称" @click="getList(true)" :advanced="true">
			<el-form-item label="语种">
				<el-select v-model="form.langId" clearable>
					<el-option
					v-for = "(item , index) in $store.state.language"
					:key="index"
					:label="item.name"
					:value="item.id">

					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="是否生效">
				<el-select v-model="form.effectStatus" clearable>
					<el-option label="生效" :value="1"></el-option>
					<el-option label="不生效" :value="0"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="生效时间">
				<el-date-picker
					v-model="form.effectTime"
					type="datetimerange"
					align="right"
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
		</search>

		<div class="keyword-word-filter-content table-margin">
			<el-card>
				<div slot="header" class="d-flex justify-space-between">
					<div>
						<el-button type="primary" size="mini" @click="uploadShow = true;">上传</el-button>
						<el-button type="text" size="mini" @click="global.utils.funDownload('/api-intl-translate-crowdsourcing-audit/dirty-words/download-template')">下载上传模板</el-button>
					</div>

					<div>
						<el-button type="primary" size="mini" @click="getReportByMutil" :disabled="mutilDisable">获取检测报告</el-button>
						<el-button type="primary" size="mini" :disabled="mutilDisable || !mutilGetReport" @click="setEnableByMutil">生效</el-button>
						<el-button type="primary" size="mini" :disabled="mutilDisable" @click="setDisableByMutil">停止</el-button>
					</div>
				</div>

				<div class="full-width">
					<el-table size="mini" border :data="tableData" @selection-change="handleSelectionChange" ref="table">
						<el-table-column
							label="全选"
					    	type="selection"
					    	width="55">
					    </el-table-column>
						<el-table-column prop="id" label="规则id" min-width="8%" align="center"></el-table-column>
						<el-table-column prop="langId" label="语种" :formatter="global.utils.format.language" min-width="8%" align="center"></el-table-column>
						<el-table-column prop="wordsContent" label="过滤词" min-width="8%" align="center"></el-table-column>
						<el-table-column prop="effectStatus" label="是否生效" min-width="8%" align="center">
							<template slot-scope="scope">
								{{scope.row.effectStatus == 1 ? '生效' : '不生效'}}
							</template>
						</el-table-column>
						<el-table-column prop="effectTime" label="生效时间" :formatter="global.utils.format.date" min-width="8%" align="center"></el-table-column>
						<el-table-column prop="filterCount" label="过滤字幕数" min-width="8%" align="center"></el-table-column>
						<el-table-column label="操作" min-width="8%" align="center">
							<template slot-scope="scope">
								<el-button v-if="scope.row.effectStatus !== 1" type="text" size="mini" @click="remove(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<div class="full-width" style="text-align:center;margin-top:20px;">
						<el-pagination				
						    layout="prev, pager, next , total , sizes"
						    :total="total"
						    :page-size.sync="page_size"
						    :current-page.sync = 'page'>
						</el-pagination>
					</div>
				</div>
			</el-card>
		</div>

		<el-dialog title="脏词词表上传" :visible.sync="uploadShow" :close-on-click-modal="false" :close-on-press-escape="false" @close="clearUploadData" width="500px">
			<div>
				<el-upload
				ref="upload"
				:on-error="fileError"
				:on-remove="removeUpload"
				:on-success="getFile"
				:before-upload="beforeAvatarUpload"
				with-credentials
				:accept="`.xlsx,.xls`"
				:action="`${http_pre}/api-intl-translate-crowdsourcing-audit/dirty-words/upload`"
				:limit="1">
					<el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip" class="el-upload__tip">上传过滤词表，格式要求xlsx、xls</div>
				</el-upload>
			</div>

			<div slot="footer" style="text-align: center">
				<el-button type="primary" size="small" :disabled="!uploadData.fileId" @click="downloadFileReport">获取检测报告</el-button>
				<el-button type="primary" size="small" :disabled="filterDisable" @click="filterWordAvaliable">过滤词表生效</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script type="text/javascript">
	export default{
		name: "keyword-word-filter",
		data(){
			return {
				form: {},
				page: 1,
				page_size: 10,
				tableData: [],
				tableMutil: [],
				total: 0,
				uploadShow: false,
				uploadData: {},
				mutilGetReport: false
			}
		},
		computed: {
			mutilDisable(){
				return this.tableMutil.length == 0;
			},
			filterDisable(){
				return !this.uploadData.fileId || !this.uploadData.reported
			}
		},
		methods: {
			getList(reload = false){
				if(reload)this.page = 1;
				let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/query`;
				$$.get(url , {
					params: {
						...this.form,
						page: this.page,
						size: this.page_size
					}
				}).then(data => {
					this.tableData = data.content;
					this.total = data.totalElements;
				}).catch(err => {
					this.$message.error(err.message);
				})
			},
			remove(row){
				this.$confirm("确定删除这条脏词？" , "提示").then(() => {
					let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/delete-by-id?id=${row.id}`;
					$$.delete(url).then(() => {
						this.$message.success("删除成功");
						this.getList();
					}).catch(err => {
						this.$message.error(err.message);
					})
				}).catch(() => {})
			},
			handleSelectionChange(val){
				this.tableMutil = _.cloneDeep(val);
			},
			//批量获取检测报告
			getReportByMutil(){
				if(!this.mutilGetReport)this.mutilGetReport = true;
				let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/download-check-report?idList=` + this.tableMutil.map(v=>v.id).toString();
				utils.funDownload(url);
			},
			setEnableByMutil(){
				this.setWordStatus(this.tableMutil.map(v => v.id) , 1);
			},
			setDisableByMutil(){
				this.setWordStatus(this.tableMutil.map(v => v.id) , 0);
			},
			setWordStatus(idList , status){
				let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/update-effect-status`;
				$$.put(url , {
					idList: idList,
					effectStatus: status
				}).then(() => {
					this.$message.success("操作成功");
					this.$refs.table.clearSelection();
					this.getList();
					if(status === 1){
						this.mutilGetReport = false;
					}
				}).catch(err => {
					this.$message.error(err.message);
				})
			},
			clearUploadData(){
				this.uploadData = {};
			},
			//文件是上传错误提示
			fileError(err, file, fileList){
				var message = JSON.parse(err.message)
				this.$message.warning(message.message)
			},
			//文件上传之前的检测
			beforeAvatarUpload (file){
				var file_ext = file.name.replace(/.+\./,"");
				if(file_ext != "xlsx" && file_ext != "xls"){
					this.$message.warning("不支持的文件格式，请重新上传");
					return false;
				}

				return true;
			},
			removeUpload(file , fileList){
				this.uploadData = {};
			},
			//文件上传成功的回调
			getFile(response, file, fileList){
				this.uploadData = _.cloneDeep(response.data);
			},
			//下载上传文件的质检报告
			downloadFileReport(){
				let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/download-check-report-by-file-id?fileId=${this.uploadData.fileId}`
				utils.funDownload(url);
				this.$set(this.uploadData , "reported" , true);
			},
			filterWordAvaliable(){
				let url = `/api-intl-translate-crowdsourcing-audit/dirty-words/effect-by-file-id`;
				$$.post(url , {
					fileId: this.uploadData.fileId
				}).then(() => {
					this.$message.success("操作成功");
					this.uploadShow = false;
					this.getList();
				}).catch(err => {
					this.$message.error(err.message);
				})
			}
		},
		watch: {
			"form.effectTime"(val){
				if(val){
					this.form.effectStartTime = new Date(val[0]).getTime();
					this.form.effectEndTime = new Date(val[1]).getTime();
				}else{
					this.form.effectStartTime = null;
					this.form.effectEndTime = null;
				}
			},
			page(){
				this.getList();
			},
			page_size(){
				this.getList(true)
			},
			uploadShow(val){
				if(!val){
					this.uploadData = {};
					this.$refs.upload.clearFiles();
				}
			}
		},
		mounted(){
			this.getList();
		}
	};
</script>